<template>
  <div class="account_recycle">
    <breadcrumb :link-list="breadcrumbList"></breadcrumb>
    <div class="header">
      <div class="headTitle">
        <img class="headIcon" src="@/assets/image/AccountDeal/gameSelectHead/accRecycleIcon.png">
        <div class="headText">账号回收</div>
        <img class="headTip" src="@/assets/image/AccountDeal/gameSelectHead/accRecycleTip.png">
      </div>
      <router-link :to="{name:'Home'}" class="headBack" v-if="step === 1" >返回首页</router-link>
      <div class="headBack" v-else @click="backBtn">返回</div>
    </div>
    <!--  账号回收 gameList -->
    <div class="recycle" v-if="step===1">
      <div class="introduce">
        <img class="introduceHead" src="@/assets/image/AccountDeal/accountRecycle/accRecycleIntr1.png">
        <div class="introduceBody">
          <div class="IB_card">
            <div class="card_title">
              <span></span>
              注意
            </div>
            <div class="card_text">合作号商均在平台缴纳高额保证金，谈好交易均通过平台交易。如号商有任何谩骂等情况发生均可带记录联系平台投诉建议进行投诉。平台一定严肃处理。</div>
            <div class="card_tip">注：平台只是提供一个方便出号的渠道，并非平台官方回收。</div>
          </div>
          <router-link tag="img" :src="require('@/assets/image/AccountDeal/accountRecycle/accRecycleIntr2.png')"
                       :to="{name:'TradeTip',query:{id:'112',parent_id:'83'}}"></router-link>
        </div>
      </div>
      <gameList :gameClick="gameClick" accRecycle :gameId="game_id"></gameList>
    </div>
    <!--  号商列表 merchantList -->
    <div class="merchantList" v-else-if="step===2">
      <div class="head">
        <img :src="selectGame.game_image" alt="">
        {{ selectGame.game_name }}&ensp;-&ensp;号商列表
      </div>
      <div class="list">
        <div class="merchant" v-for="(item,index) in merchantList" :key="index" @click="merchantClick(item)">
          <div class="name">号商：&ensp;{{ item.name }}</div>
          <div class="info">
            <div class="game">
              <div class="label">负责游戏：</div>
              <div class="gameName">
                {{ item.game_name }}
              </div>
            </div>
            <div class="other">
              <div class="safeguard">
                <img class="headIcon" src="@/assets/image/AccountDeal/accountRecycle/safeIcon.png">
                已缴纳保证金
              </div>
              <div class="infoBtn">
                号商详情 <i class="el-icon-caret-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  号商详情 merchantInfo -->
    <div class="merchantInfo" v-else-if="step===3">
      <div class="head">
        <div class="title">
          <img class="headIcon" src="@/assets/image/AccountDeal/accountRecycle/subtract.png">
          号商详情
        </div>
        <div class="merchant">
          <div class="name">号商：&ensp;{{ merchantInfo.name }}</div>
          <div class="info">
            <div class="game">
              <div>负责游戏：{{ merchantInfo.game_name }}</div>
              <div><img class="headIcon" src="@/assets/image/AccountDeal/accountRecycle/safeIcon.png">已缴纳保证金</div>
            </div>
            <a class="btn" :href="merchantInfo.link" :target="merchantInfo.link">联系号商</a>
          </div>
        </div>
      </div>
      <div  class="ql-container ql-snow">
        <p id="merchantInfo" class="ql-editor" v-html="merchantInfo.cdata"></p>
      </div>
    </div>
  </div>
</template>
<script>

import {apiRecyclingList,apiRecyclingInfo} from "@/request/API";
import breadcrumb from "@/components/breadcrumb";
import gameList from "@/components/gameList/index"

export default {
  name: '',
  props: {},
  components: {
    breadcrumb,
    gameList
  },
  data() {
    return {
      // 站内信详情联系号商，游戏id
      game_id: this.$route.query.game_id || '',
      // gameList 、 merchantList 、 merchantInfo
      step: 1,
      // 面包屑
      breadcrumbList: [
        {name: '账号交易'},
        {link: 'AccountRecycle', name: '账号回收'}
      ],
      // 选择的游戏
      selectGame: '',
      //号商列表
      merchantList:[],
      //号商详情
      merchantInfo:{}
    }
  },
  methods: {
    /**
     * 点击游戏事件
     * @param game
     */
    gameClick(game) {
      this.selectGame = game
      document.body.scrollTop = document.documentElement.scrollTop = 157
      this.step++
      this.getRecyclingList(game.game_id)
    },
    /**
     * 点击号商事件
     * @param merchant
     */
    merchantClick(merchant) {
      this.merchantInfo = merchant
      this.step++
    },
    /**
     * 返回按钮
     */
    backBtn() {
      // 如果站内信详情跳转则返回站内信详情，否则step--
      if(this.game_id){
        this.$router.back()
      }else{
        this.step--
      }
    },
    /**
     * 获取号商列表
     * @param game_id
     */
    getRecyclingList(game_id){
      apiRecyclingList({
        game_id:game_id
      }).then(res=>{
        this.merchantList = res.data
      })
    },
  },
  mounted() {},
  watch: {
    step(val) {
      switch (val) {
        case 1:
          this.breadcrumbList = [
            {name: '账号交易'},
            {link: 'AccountRecycle', name: '账号回收'}
          ]
          break;
        case 2:
          this.breadcrumbList = [
            {name: '账号回收'},
            {name: '号商列表'},
          ]
          break;
        case 3:
          this.breadcrumbList = [
            {name: '账号回收'},
            {name: '号商列表'},
            {name: '号商详情'}
          ]
          break;
      }
    }
  },
  computed: {}
}
</script>

<style  scoped>
.ql-container{
  border: none !important;
}
#merchantInfo >>> img{
  height: 100% !important;
  object-fit: cover !important;
}
</style>

<style lang='less' scoped>
.account_recycle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1200px;

  .header {
    width: calc(1200px - 88px);
    height: 70px;
    background: linear-gradient(90deg, #163279 0%, rgba(0,8,77,0.8000) 100%);
    border-radius: 10px 10px 0 0;
    padding: 0 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .headTitle {
      display: flex;
      flex-direction: row;
      align-items: center;

      .headText {
        font-size: 24px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        text-align: CENTER;
        color: #ffffff;
        margin: 0 29px 0 8px;
      }

    }

    .headBack {
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .recycle {
    display: flex;
    flex-direction: column;
    align-items: center;

    .introduce {
      margin: 0 0 18px;

      .introduceHead {
        margin: 18px 0 4px;
      }

      .introduceBody {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        .IB_card {
          margin: 3px 0 0;
          width: 640px;
          height: 144px;
          padding: 23px 34px 23px 43px;
          background: #ffffff;
          border-radius: 10px;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          .card_title {
            font-size: 20px;
            font-family: PingFang SC, PingFang SC-Semibold;
            font-weight: 600;
            color: #434ED6;
            line-height: 20px;

            span {
              display: inline-block;
              width: 4px;
              height: 14px;
              background: #434ED6;
              margin: 0 14px 0 0;
            }
          }

          .card_text {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #5b5b5b;
            line-height: 25px;
          }

          .card_tip {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            color: #434ED6;
            line-height: 16px;
          }
        }

        img {
          cursor: pointer;
        }
      }
    }
  }

  .merchantList {
    width: calc(1200px - 50px);
    background: #ffffff;
    padding: 0 25px 100px;

    .head {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 40px 18px;
      font-size: 24px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #4a4a4a;
      border-bottom: 2px solid #f0f0f0;

      img {
        width: 80px;
        height: 80px;
        border-radius: 20px;
        margin: 0 25px 0 0;
      }
    }

    .list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0 30px;

      .merchant {
        width: 530px;
        background: #f9f9f9;
        border-radius: 10px;
        margin: 20px 5px 0;
        cursor: pointer;

        .name {
          border-radius: 10px 10px 0 0;
          height: 58px;
          line-height: 58px;
          background: #283748;
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #ffffff;
          padding: 0 0 0 21px;
        }

        .info {
          padding: 0 20px;

          .game {
            padding: 13px 0;
            border-bottom: 1px solid #e0e3e5;
            display: flex;
            flex-direction: row;

            .label {
              height: 52px;
              flex-shrink: 0;
            }

            .gameName {
              width: 355px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-align: justify;
              text-justify: newspaper;
              word-break: break-all;
            }

            div {
              font-size: 16px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: LEFT;
              color: #000000;
              line-height: 25px;
            }
          }

          .other {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 11px 0 12px;

            .safeguard {
              display: flex;
              align-items: center;
              font-size: 16px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              color: #000000;

              img {
                margin: 0 8px 0 0;
              }
            }

            .infoBtn {
              font-size: 16px;
              font-family: PingFang SC, PingFang SC-Medium;
              font-weight: 500;
              color: #ffae21;
            }
          }
        }
      }
    }
  }

  .merchantInfo {
    width: calc(1200px - 50px);
    background: #ffffff;
    padding: 30px 25px 50px;

    .head {
      margin: 0 0 19px;
      padding: 0 17px 20px;
      border-bottom: 2px solid #f0f0f0;

      .title {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        color: #000000;
        margin: 0 0 20px;

        img {
          margin: 0 8px 0 0;
        }
      }

      .merchant {
        height: 151px;
        background: #f9f9f9;
        border-radius: 10px;

        .name {
          border-radius: 10px 10px 0 0;
          background: #283748;
          padding: 16px 21px 19px;
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          text-align: LEFT;
          color: #ffffff;
        }

        .info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 13px 27px 17px 21px;

          .game {
            div {
              width: 700px;
              font-size: 16px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              color: #000000;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            div:last-of-type {
              display: flex;
              align-items: center;
              margin: 16px 0 0;

              img {
                margin: 0 8px 0 0;
              }
            }
          }

          .btn {
            width: 160px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #434ED6;
            border-radius: 30px;
            cursor: pointer;
            color: #FFFFFF;
          }
        }
      }
    }

    #merchantInfo {
      margin: 0 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
